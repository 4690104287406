import Vue from 'vue'
import i18n from "../i18n/i18n"

export const handleError = (error) => {
  if (error.code === 'ECONNABORTED') {
    Vue.prototype.$notify.error({
      title: i18n.t('error')/*錯誤*/,
      message: i18n.t('api_econnaborted')
    })
  } else if (error.message && error.message.includes('timeout')) {
    Vue.prototype.$notify.error({
      title: i18n.t('error')/*錯誤*/,
      message: i18n.t('search_timeout_message')/* 內容過多，請縮小查詢範圍*/
    })
  } else if (error.response) {
    // 伺服器回應但狀態碼出錯，例如 404 或 500
    Vue.prototype.$notify.error({
      title: i18n.t('error')/*錯誤*/,
      message: `${error.response.status}: ${error.response.statusText}`
    })
  } else {
    // 其他錯誤處理
    Vue.prototype.$notify.error({
      title: i18n.t('error')/*錯誤*/,
      message: error.message
    })
  }
}