import Vue from 'vue'
import i18n from "../../i18n/i18n"
import { setFrDefaultValue } from '@/config/configFr.js'
import {
  apiGetDefaultSetting,
  apiGetUserSetting,
  apiGetUserInfo,
  apiEditUser,
  apiPostUserSetting,
  apiPutUserSetting,
} from '@/api/index.js'

import { 
  apiGetUserSubscribers, 
  apiPostUserSubscriber,
  apiDeleteUserSubscriber,
  apiGetUserWebhooks,
  apiEditUserWebhook,
} from '@/api/v4'

const initialState = () => ({
  origDefaultSetting: null,
  currDefaultSetting: 1,
  faceSizeRatio: 10, // 透過此參數連動canvas與右側scroll bar 調整人臉框大小
  defaultSetting: {},
  userSetting: {},
  theSetting: {},
  origNote: '',
  note: '',
  origSubscribers: [],
  subscribers: [],
  origNotifyFilterMode: 2, // 記錄原始事件通報模式
  notifyFilterMode: 2, // 事件通報模式 用 GET API /api/web/user-info 讀取

  // LINE notify webhook
  isWebhookModified: false, // webhook 是否有修改
})

const state = initialState()

const getters = {
  userIndex(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.index
  },
  userId(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.id
  },
  deviceModelId(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.deviceModelId
  },
  isSettingReady(state) {
    return Object.keys(state.defaultSetting).length > 0 && Object.keys(state.theSetting).length > 0
  },
  currentSetting(state) {
    // defaultSetting = 0: userSetting 設備客製/可編輯, 
    // defaultSetting = 1: defaultSetting 系統預設/不可編輯
    return state.currDefaultSetting === 0 ? state.theSetting : state.defaultSetting
  },
  faceSizeRatio(state, getters) {
    return getters.currentSetting?.setting?.channel[0].fr.faceSizeRatio
  },
  isSettingSrcModified(state) {
    return state.currDefaultSetting !== state.origDefaultSetting
  },
  isSettingModified(state) {
    if (JSON.stringify(state.theSetting.setting) === JSON.stringify(state.userSetting.setting)
      || (JSON.stringify(state.theSetting.setting) === JSON.stringify(state.defaultSetting.setting))) 
      return false
    return true
  },
  isNoteModified(state) {
    return state.note !== state.origNote
  },
  isNotifyFilterModeModified(state) {
    return state.notifyFilterMode !== state.origNotifyFilterMode
  },
  isSubscribersModified(state) {
    const origSubscribers = state.origSubscribers.map(subscriber => subscriber.subscriberId)
    const currSubscribers = state.subscribers.map(subscriber => subscriber.subscriberId)
    const addIds = currSubscribers.filter(subscriber => !origSubscribers.includes(subscriber))
    const minusIds = origSubscribers.filter(subscriber => !currSubscribers.includes(subscriber))
    return addIds.length > 0 || minusIds.length > 0
  },
  isModified(state, getters) {
    return getters.isSettingSrcModified || 
           getters.isSettingModified || 
           getters.isNoteModified ||
           getters.isNotifyFilterModeModified ||
           getters.isSubscribersModified ||
           state.isWebhookModified
  }
}

const mutations = {
  resetState(state) {
    Object.assign(state, initialState())
  },
  updateOrigDefaultSetting(state, value) {
    state.origDefaultSetting = value
  },
  updateCurrDefaultSetting(state, value) {
    state.currDefaultSetting = value
  },
  updateFaceSizeRatio(state, value) {
    state.theSetting.setting.channel[0].fr.faceSizeRatio = value
  },
  updateDefaultSetting(state, data) {
    state.defaultSetting = data
  },
  updateUserSetting(state, data) {
    state.userSetting = data
  },
  updateTheSetting(state, data) {
    state.theSetting = data
  },
  updateSetting(state, data) {
    state.theSetting.setting = data
  },
  updateOrigNote(state, data) {
    state.origNote = data
  },
  updateNote(state, data) {
    state.note = data
  },
  updateTagFilter(state, data) {
    state.theSetting.setting.deviceConfig.fr.tagFilter = data
  },
  updateOrigSubscribers(state, data) {
    state.origSubscribers = data
  },
  updateSubscribers(state, data) {
    state.subscribers = data
  },
  updateROI(state, data) {
    state.theSetting.setting.channel[0].fr.roi = data
  },
  updateOrigNotifyFilterMode(state, data) {
    state.origNotifyFilterMode = data
  },
  updateNotifyFilterMode(state, data) {
    state.notifyFilterMode = data
  },
  updateIsWebhookModified(state, value) {
    state.isWebhookModified = value
  }
}

const actions = {
  async onInit({ state, dispatch }) {
    dispatch('getFrTagList', null, { root: true })
    dispatch('getUserSubscribers')
    await dispatch('getUserInfo')  // get defaultSetting, frNote
    await dispatch('getDefaultSetting')
    await dispatch('getUserSetting')

    // -------
    // 若為系統預設先暫時設定為設備客製，不能切換到系統預設
    if (state.currDefaultSetting === 1) {
      dispatch('onUpdateCurrDefaultSetting', 0)
      await dispatch('editSettingSrc') // 參數來源
    }
    // -------
    dispatch('initSetting')
  },
  async getDefaultSetting({ commit, getters }) {
    try {
      const res = await apiGetDefaultSetting(getters.deviceModelId)
      if (res.status === 200) {
        setFrDefaultValue(res.data.setting)
        commit('updateDefaultSetting', res.data)
      }
    } catch (err) {
      Vue.prototype.$notify({
        message: err.response.data.message,
        type: 'error',
      })
    }
  },
  async getUserSetting({ commit, getters }) {
    try {
      const res = await apiGetUserSetting(getters.userIndex)
      if (res.status === 200) {
        setFrDefaultValue(res.data.setting)
        commit('updateUserSetting', res.data)
      } else {
        commit('updateUserSetting', {})
      }
    } catch (err) {
      commit('updateUserSetting', {})
      console.log('發生錯誤：', err)
    }
  },
  async getUserInfo({ commit, getters }) {
    try {
      const res = await apiGetUserInfo(getters.userIndex)
      if (res.status === 200) {
        commit('updateOrigDefaultSetting', res.data.defaultSetting)
        commit('updateCurrDefaultSetting', res.data.defaultSetting)
        commit('updateNote', res.data.frNote)
        commit('updateOrigNote', res.data.frNote)
        commit('updateOrigNotifyFilterMode', res.data.frNotifyFilterMode)
        commit('updateNotifyFilterMode', res.data.frNotifyFilterMode)
      }
    } catch (err) {
      console.log('發生錯誤：', err)
    }
  },
  initSetting({ state, commit }) {
    // defaultSetting = 0: userSetting 設備客製/可編輯, 
    // defaultSetting = 1: defaultSetting 系統預設/不可編輯
    if (state.origDefaultSetting === 1) {
      commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
    } else {
      if (Object.keys(state.userSetting).length === 0) {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
      } else {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.userSetting)))
      }
    }
  },
  // 修改參數來源 --> 呼叫 apiEditUser
  async editSettingSrc({ state, getters, dispatch }) {
    if (!getters.isSettingSrcModified) return
    let type = '', message = ''
    try {
      const data = {
        id: getters.userId,
        frNote: state.note,
        defaultSetting: state.currDefaultSetting
      }

      await apiEditUser(data)
      dispatch('sendLineNotify') // 發送 LINE notify
      
      // 若參數來源有修改，則需呼叫getUserInfo --> initSetting
      await dispatch('getUserInfo')
      await dispatch('getUserSetting')
      dispatch('initSetting')
      type = 'success'
      message = i18n.t('ai_setting_success')/*設定成功*/
    } catch (err) {
      type = 'error'
      message = i18n.t('ai_setting_fail')/*設定失敗*/
    }

    Vue.prototype.$notify({
      type: type,
      title: i18n.t('ai_param_source')/*參數來源*/,
      message: message
    })
  },
  // 修改備註、事件通報模式 --> 呼叫 apiEditUser
  async editNote({ state, getters, dispatch }) {
    if (
      !getters.isNoteModified && 
      !getters.isNotifyFilterModeModified
    ) return
    let type = '', title = '', message = ''
    try {
      const data = {
        id: getters.userId,
        frNote: state.note,
      }
      
      if (getters.isNoteModified) {
        title += title ? '、' : ''
        title += i18n.t('ai_note')/*備註*/
      }

      if (getters.isNotifyFilterModeModified) {
        title += title ? '、' : ''
        title += i18n.t('ai_notify_filter_mode')/*事件通報模式*/
        data.frNotifyFilterMode = state.notifyFilterMode
      }

      await apiEditUser(data)
      dispatch('sendLineNotify') // 發送 LINE notify
      
      // 修改後需呼叫getUserInfo --> initSetting
      await dispatch('getUserInfo')
      await dispatch('getUserSetting')
      dispatch('initSetting')
      type = 'success'
      message = i18n.t('ai_setting_success')/*設定成功*/
    } catch (err) {
      type = 'error'
      message = i18n.t('ai_setting_fail')/*設定失敗*/
    }

    Vue.prototype.$notify({
      type: type,
      title: title,
      message: message,
    })
  },
  onUpdateCurrDefaultSetting({ commit }, value) {
    commit('updateCurrDefaultSetting', value)
    if (value === 1) {
      commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
    } else {
      if (Object.keys(state.userSetting).length === 0) {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
      } else {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.userSetting)))
      }
    }
  },
  async editUserSetting({ state, getters, dispatch }) {
    if (!getters.isSettingModified) return
    let type, message    
    try {
      const data = {
        userId: String(getters.userIndex), // userId要帶入user.index
        setting: getters.currentSetting.setting,
      }
      // 判斷userSetting是否存在，存在則為修改，不存在則為新增
      if (Object.keys(state.userSetting).length === 0) 
        await apiPostUserSetting(data)
      else
        await apiPutUserSetting(data)

      // 若辨識參數有修改，需要再呼叫 user api (帶入frNote, 以便發通報)
      await apiEditUser({
        id: getters.userId,
        frNote: state.note,
      })
      dispatch('sendLineNotify') // 發送 LINE notify
      
      type = 'success'
      message = i18n.t('ai_setting_success') /*設定成功*/
    } catch (err) {
      type = 'error'
      message = i18n.t('ai_setting_fail') /*設定失敗*/
    }

    Vue.prototype.$notify({
      title: i18n.t('ai_recognition_setting') /*辨識設定*/,
      message: message || '',
      type: type || 'error',
    })
  },
  async getUserSubscribers({ state, commit, getters }) {
    try {
      const res = await apiGetUserSubscribers(getters.userIndex, 3) // 第2個參數 model => LPR: 1, OR: 2, FR: 3
      const data = res.status === 200 ? res.data : []
      commit('updateOrigSubscribers', data)
      commit('updateSubscribers', data)
    } catch (err) {
      commit('updateSubscribers', state.origSubscribers) // 若發生錯誤，則將subscribers設為origSubscribers
      console.log('發生錯誤：', err)
    }
  },
  async handleUserSubscribers({ state, getters, dispatch, rootState }) {
    const origSubscribers = state.origSubscribers.map(subscriber => subscriber.subscriberId)
    const currSubscribers = state.subscribers.map(subscriber => subscriber.subscriberId)
    const addIds = currSubscribers.filter(subscriber => !origSubscribers.includes(subscriber))
    
    // 要 delete 接收人前, 需先檢查該接收人是否有在自己的可視 list 中, 若無, 則不能刪除該接收人
    const minusIds = origSubscribers.filter(subscriber => !currSubscribers.includes(subscriber) && 
      rootState.account.userList.find(user => user.index === Number(subscriber)))
    
    if (addIds.length !== 0 || minusIds.length !== 0) {
      const promisesAdd = addIds.map(async(id) => {
        const data = {
          userId: String(getters.userIndex),
          subscriberId: id,
          model: 3, // model: 1:LPR, 2:OR, 3:FR
        }
        await apiPostUserSubscriber(data)
      })

      const promisesDel = minusIds.map(async(id) => {
        const data = {
          userId: String(getters.userIndex),
          subscriberId: id,
          model: 3, // model: 1:LPR, 2:OR, 3:FR
        }
        await apiDeleteUserSubscriber(data)
      })

      const promises = promisesAdd.concat(promisesDel)
      
      Promise.all(promises)
        .then(() => {
          dispatch('getUserSubscribers')
          Vue.prototype.$notify({
            title: i18n.t('ai_subscriber') /*接收人*/,
            message: i18n.t('ai_setting_success') /*設定成功*/,
            type: 'success'
          })
        })
        .catch(() => {
          dispatch('getUserSubscribers')
          Vue.prototype.$notify({
            title: i18n.t('ai_subscriber') /*接收人*/,
            message: i18n.t('ai_setting_fail') /*設定失敗*/,
            type: 'error'
          })
        })
      }
  },
  restoreData({ state, commit, dispatch }) {
    commit('updateCurrDefaultSetting', state.origDefaultSetting)
    // commit('updateTheSetting', JSON.parse(JSON.stringify(state.userSetting)))
    dispatch('initSetting')
    commit('updateNote', state.origNote)
    commit('updateNotifyFilterMode', state.origNotifyFilterMode)
    commit('updateSubscribers', state.origSubscribers)
  },
  async sendLineNotify({ state, rootGetters }) {
    // 取得 LINE notify webhooks --> 針對有啟用的 webhook 發送通知 (呼叫 edit webhook api)
    try {
      // const modelMap = { lpr: 1, or: 2, fr: 3 }
      const params = { 
        userIds: String(rootGetters.rightClickUser.index),
        models: 3
      }
      const res = await apiGetUserWebhooks(params)
      const webhooks = res.data
      webhooks.forEach(webhook => {
        if (webhook.enabled === 1) {
          apiEditUserWebhook({ 
            id: webhook.id,
            userId: webhook.userId,
          })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}



export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}