import Vue from 'vue'
import i18n from "../../i18n/i18n"
import { lprDeviceIds, patrolCarDeviceIds, setLprDefaultValue } from '@/config/configLpr.js'
import { aicamDeviceModelId } from '@/config/account'
import {
  apiGetTagList, 
  apiGetFrTagList,
  apiGetDefaultSetting,
  apiGetUserSetting,
  apiGetUserInfo,
  apiEditUser,
  apiPostUserSetting,
  apiPutUserSetting,
} from '@/api/index.js'

import { 
  apiGetUserSubscribers, 
  apiPostUserSubscriber,
  apiDeleteUserSubscriber,
  apiGetUserWebhooks,
  apiEditUserWebhook,
} from '@/api/v4'

const initialState = () => ({
  origDefaultSetting: null,
  currDefaultSetting: 1,
  faceSizeRatio: 10, // 透過此參數連動canvas與右側scroll bar 調整人臉框大小
  defaultSetting: {},
  userSetting: {},
  theSetting: {},
  newFillSettingKeys: [], // 用來記錄userSetting缺少的參數
  tagList: [],
  origNote: '',
  note: '',
  origSubscribers: [],
  subscribers: [],
  camIndex: 0,
  origNotifyFilterMode: 2, // 記錄原始事件通報模式
  notifyFilterMode: 2, // 事件通報模式 用 GET API /api/web/user-info 讀取

  // LINE notify webhook
  isWebhookModified: false, // webhook 是否有修改
})

const state = initialState()

const getters = {
  userIndex(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.index
  },
  userId(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.id
  },
  deviceModelId(state, getters, rootState, rootGetters) {
    // 若有 user setting, 以 user setting 中的 type 當作設備類型; 若無則以設備帳號的 deviceModelId 當作設備類型
    // 原因：AiCam 帳號設定的設備類型與設備實際被設定的設備類型，兩者可能不同，故以 user setting 為主
    if (getters.currentSetting.setting) return getters.currentSetting.setting.type
    return rootGetters.rightClickUser.deviceModelId
  },
  isAiCam(state, getters) {
    return aicamDeviceModelId.includes(getters.deviceModelId)
  },
  isLprCam(state, getters) {
    return lprDeviceIds.includes(getters.deviceModelId)
  },
  isPatrolCar(state, getters) {
    return patrolCarDeviceIds.includes(getters.deviceModelId)
  },
  isSettingReady(state) {
    return Object.keys(state.defaultSetting).length > 0 && Object.keys(state.theSetting).length > 0
  },
  currentSetting(state) {
    // defaultSetting = 0: userSetting 設備客製/可編輯, 
    // defaultSetting = 1: defaultSetting 系統預設/不可編輯
    return state.currDefaultSetting === 0 ? state.theSetting : state.defaultSetting
  },
  aiModelType(state, getters) {
    // tw, jp
    return getters.currentSetting.setting.aiModelType
  },
  lprModelType(state, getters) {
    // lprTw, lprJp
    return 'lpr' + getters.aiModelType.slice(0, 1).toUpperCase() + getters.aiModelType.slice(1)
  },
  useChannel(state, getters) {
    // patrol car 才有的參數
    // imageSourceType: 0: 外接鏡頭(channel), 1: 內建鏡頭(channelInternalCam)
    const imageSourceType = getters.currentSetting.setting?.imageSourceType
    const channelSrc = imageSourceType === 1 ? 'channelInternalCam' : 'channel'
    return getters.currentSetting.setting[channelSrc]
  },
  Roi(state, getters) {
    return getters.useChannel[state.camIndex][getters.lprModelType].roi
  },
  lprSizeRatio(state, getters) {
    return getters.useChannel[state.camIndex][getters.lprModelType].lprSizeRatio
  },
  indent(state, getters) {
    return getters.useChannel[state.camIndex][getters.lprModelType].indent
  },
  isSettingSrcModified(state) {
    return state.currDefaultSetting !== state.origDefaultSetting
  },
  isSettingModified(state) {
    // if (state.newFillSettingKeys.length > 0) return true
    if (JSON.stringify(state.theSetting.setting) === JSON.stringify(state.userSetting.setting) || 
      (JSON.stringify(state.theSetting.setting) === JSON.stringify(state.defaultSetting.setting))) 
      return false
    return true
  },
  isNoteModified(state) {
    return state.note !== state.origNote
  },
  isNotifyFilterModeModified(state) {
    return state.notifyFilterMode !== state.origNotifyFilterMode
  },
  isSubscribersModified(state) {
    const origSubscribers = state.origSubscribers.map(subscriber => subscriber.subscriberId)
    const currSubscribers = state.subscribers.map(subscriber => subscriber.subscriberId)
    const addIds = currSubscribers.filter(subscriber => !origSubscribers.includes(subscriber))
    const minusIds = origSubscribers.filter(subscriber => !currSubscribers.includes(subscriber))
    return addIds.length > 0 || minusIds.length > 0
  },
  isModified(state, getters) {
    return getters.isSettingSrcModified || 
           getters.isSettingModified || 
           getters.isNoteModified ||
           getters.isNotifyFilterModeModified ||
           getters.isSubscribersModified ||
           state.isWebhookModified
  }
}

const mutations = {
  resetState(state) {
    Object.assign(state, initialState())
  },
  updateOrigDefaultSetting(state, value) {
    state.origDefaultSetting = value
  },
  updateCurrDefaultSetting(state, value) {
    state.currDefaultSetting = value
  },
  updateDefaultSetting(state, data) {
    state.defaultSetting = data
  },
  updateUserSetting(state, data) {
    state.userSetting = data
  },
  updateTheSetting(state, data) {
    state.theSetting = data
  },
  updateSetting(state, data) {
    state.theSetting.setting = data
  },
  updateImageSourceType(state, data) {
    // patrol car 才有的參數
    // imageSourceType: 0: 外接鏡頭, 1: 內建鏡頭
    state.theSetting.setting.imageSourceType = data
    state.camIndex = 0 // 切換imageSourceType，一定得重置camIndex＝0，因為內建鏡頭只有一個
  },
  updateAiModelType(state, data) {
    state.theSetting.setting.aiModelType = data
  },
  updateNewFillSettingKeys(state, data) {
    state.newFillSettingKeys = data
  },
  updateTagList(state, data) {
    state.tagList = data
  },
  updateOrigNote(state, data) {
    state.origNote = data
  },
  updateNote(state, data) {
    state.note = data
  },
  updateChannelAccount(state, payload) {
    state.theSetting.setting.channel[payload.channelIndex].account = payload.account
  },
  updateChannelServer(state, payload) {
    state.theSetting.setting.channel[payload.channelIndex].server = payload.server
  },
  updateTagFilter(state, data) {
    const imageSourceType = state.theSetting.setting.imageSourceType
    const deviceConfigSrc = imageSourceType === 1 ? 'deviceConfigInternalCam' : 'deviceConfig'
    const aiModelType = state.theSetting.setting.aiModelType
    const lprModelType = 'lpr' + aiModelType.slice(0, 1).toUpperCase() + aiModelType.slice(1)
    state.theSetting.setting[deviceConfigSrc][lprModelType].tagFilter = data
  },
  updateOrigSubscribers(state, data) {
    state.origSubscribers = data
  },
  updateSubscribers(state, data) {
    state.subscribers = data
  },
  updateROI(state, data) {
    const imageSourceType = state.theSetting.setting.imageSourceType
    const channelSrc = imageSourceType === 1 ? 'channelInternalCam' : 'channel'
    const aiModelType = state.theSetting.setting.aiModelType
    const lprModelType = 'lpr' + aiModelType.slice(0, 1).toUpperCase() + aiModelType.slice(1)
    state.theSetting.setting[channelSrc][state.camIndex][lprModelType].roi = data
  },
  updateCamIndex(state, data) {
    state.camIndex = data
  },
  updateLprSizeRatio(state, data) {
    const imageSourceType = state.theSetting.setting.imageSourceType
    const channelSrc = imageSourceType === 1 ? 'channelInternalCam' : 'channel'
    const aiModelType = state.theSetting.setting.aiModelType
    const lprModelType = 'lpr' + aiModelType.slice(0, 1).toUpperCase() + aiModelType.slice(1)
    state.theSetting.setting[channelSrc][state.camIndex][lprModelType].lprSizeRatio = data
  }, 
  updateOrigNotifyFilterMode(state, data) {
    state.origNotifyFilterMode = data
  },
  updateNotifyFilterMode(state, data) {
    state.notifyFilterMode = data
  },
  updateIsWebhookModified(state, value) {
    state.isWebhookModified = value
  }
}

const actions = {
  async onInit({state, getters, dispatch}) {
    dispatch('getTagList')
    dispatch('getUserSubscribers')
    await dispatch('getUserInfo') // get defaultSetting, frNote / lprNote
    await dispatch('getDefaultSetting')
    await dispatch('getUserSetting')
    
    // -------
    // 若為AiCam，永遠只能是設備客製
    if (getters.isAiCam) {
      if (state.currDefaultSetting === 1) {
        dispatch('onUpdateCurrDefaultSetting', 0)
        await dispatch('editSettingSrc') // 參數來源
      }
    }
    // -------

    dispatch('initSetting')
  },
  async getTagList({commit, rootState}) {
    try {
      let res = {}
      if (rootState.aibox.aiMode === 'lpr')
        res = await apiGetTagList()
      else if (rootState.aibox.aiMode === 'fr')
        res = await apiGetFrTagList()
      if (res.status === 200) {
        commit('updateTagList', res.data.tagList)
      }
    } catch (err) {
      console.log('發生錯誤：', err)
    }
  },
  
  async getDefaultSetting({ commit, getters }) {
    try {
      const res = await apiGetDefaultSetting(getters.deviceModelId)
      if (res.status === 200) {
        // 檢查參數是否有缺少，若有缺少則補上 defaultSetting的值，若無則使用 configLpr 設定的預設值
        setLprDefaultValue(res.data.setting)
        commit('updateDefaultSetting', res.data)
      }
    } catch (err) {
      Vue.prototype.$notify({
        message: err.response.data.message,
        type: 'error',
      })
      commit('updateDefaultSetting', {})
    }
  },
  async getUserSetting({ state, commit, getters }) {
    try {
      const res = await apiGetUserSetting(getters.userIndex)
      if (res.status === 200) {
        // 檢查參數是否有缺少，若有缺少則補上 defaultSetting的值，若無則使用 configLpr 設定的預設值
        const fillTags = setLprDefaultValue(res.data.setting, state.defaultSetting.setting)
        commit('updateUserSetting', res.data)
        commit('updateNewFillSettingKeys', fillTags)
        // console.log('fillTags:', fillTags)
      } else {
        commit('updateUserSetting', {})
      }
    } catch (err) {
      commit('updateUserSetting', {})
    }
  },
  async getUserInfo({ commit, getters, rootState }) {
    try {
      const res = await apiGetUserInfo(getters.userIndex)
      if (res.status === 200) {
        commit('updateOrigDefaultSetting', res.data.defaultSetting)
        commit('updateCurrDefaultSetting', res.data.defaultSetting)

        let note = ''
        switch (rootState.aibox.aiMode) {
          case 'fr':
            note = res.data.frNote
            break
          case 'lpr':
            note = res.data.lprNote
            break
        }  
        commit('updateNote', note)
        commit('updateOrigNote', note)

        commit('updateOrigNotifyFilterMode', res.data.lprNotifyFilterMode)
        commit('updateNotifyFilterMode', res.data.lprNotifyFilterMode)
      }
    } catch (err) {
      console.log('發生錯誤：', err)
    }
  },
  initSetting({ state, getters, commit, dispatch }) {
    // defaultSetting = 0: userSetting 設備客製/可編輯, 
    // defaultSetting = 1: defaultSetting 系統預設/不可編輯
    if (state.origDefaultSetting === 1) {
      commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
    } else {
      if (Object.keys(state.userSetting).length === 0 || Object.keys(state.userSetting.setting).length === 0) {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
      } else {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.userSetting)))        
      }
    }

    // 因AiCam有兩個channel，若account有值，要找跟自己帳號一致account的channel設定參數
    // 若沒有設定account, server則預設為使用者帳號, server
    // 若一開始從defaultSetting取得的參數，account會是空的，則需要設定account, server
    if (getters.isAiCam) {
      dispatch('setAccountServer')
    }
  },
  setAccountServer({ state, getters, commit }) {
    const aicamChannelIdx = state.theSetting.setting.channel.findIndex(channel => channel.account === getters.userId)
    if (aicamChannelIdx === -1) {
      commit('updateChannelAccount', { 
        channelIndex: 0, 
        account: getters.userId 
      })
      commit('updateChannelServer', {
        channelIndex: 0,
        server: process.env.NODE_ENV === 'production' ? location.hostname : process.env.VUE_APP_API_HOSTNAME
      })
    }
  },
  // 修改參數來源 --> 呼叫 apiEditUser
  async editSettingSrc({ state, getters, dispatch }) {
    if (!getters.isSettingSrcModified) return
    let type = '', message = ''
    try {
      const data = {
        id: getters.userId,
        lprNote: state.note,
        defaultSetting: state.currDefaultSetting
      }

      await apiEditUser(data)
      dispatch('sendLineNotify') // 發送 LINE notify

      // 若參數來源有修改，則需呼叫getUserInfo --> initSetting
      await dispatch('getUserInfo')
      await dispatch('getUserSetting')
      dispatch('initSetting')
      type = 'success'
      message = i18n.t('ai_setting_success')/*設定成功*/
    } catch (err) {
      type = 'error'
      message = i18n.t('ai_setting_fail')/*設定失敗*/
    }

    Vue.prototype.$notify({
      type: type,
      title: i18n.t('ai_param_source')/*參數來源*/,
      message: message,
    })
  },
  // 修改備註、事件通報模式 --> 呼叫 apiEditUser
  async editNote({ state, getters, dispatch }) {
    if ( 
      !getters.isNoteModified && 
      !getters.isNotifyFilterModeModified
    ) return
    let type = '', title = '', message = ''
    try {
      const data = {
        id: getters.userId,
        lprNote: state.note,
      }

      if (getters.isNoteModified) {
        title += title ? '、' : ''
        title += i18n.t('ai_note')/*備註*/
      }
      
      if (getters.isNotifyFilterModeModified) {
        title += title ? '、' : ''
        title += i18n.t('ai_notify_filter_mode')/*事件通報模式*/
        data.lprNotifyFilterMode = state.notifyFilterMode
      }

      await apiEditUser(data)
      dispatch('sendLineNotify') // 發送 LINE notify

      // 呼叫getUserInfo --> initSetting
      await dispatch('getUserInfo')
      await dispatch('getUserSetting')
      dispatch('initSetting')
      type = 'success'
      message = i18n.t('ai_setting_success')/*設定成功*/
    } catch (err) {
      type = 'error'
      message = i18n.t('ai_setting_fail')/*設定失敗*/
    }

    Vue.prototype.$notify({
      type: type,
      title: title,
      message: message,
    })
  },
  onUpdateCurrDefaultSetting({ commit }, value) {
    commit('updateCurrDefaultSetting', value)
    if (value === 1) {
      commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
    } else {
      if (Object.keys(state.userSetting).length === 0 || Object.keys(state.userSetting.setting).length === 0) {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
      } else {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.userSetting)))
      }
    }
  },
  async editUserSetting({ state, getters, dispatch }) {
    let type, message
    try {
      if (!getters.isSettingModified) return

      const data = {
        userId: String(getters.userIndex), // userId要帶入user.index
        setting: getters.currentSetting.setting,
      }
      // 判斷userSetting是否存在，存在則為修改，不存在則為新增
      if (Object.keys(state.userSetting).length === 0) 
        await apiPostUserSetting(data)
      else
        await apiPutUserSetting(data)

      // 若辨識參數有修改，需要再呼叫 user api (帶入lprNote, 以便發通報)
      await apiEditUser({
        id: getters.userId,
        lprNote: state.note,
      })
      dispatch('sendLineNotify') // 發送 LINE notify

      type = 'success'
      message = i18n.t('ai_setting_success') /*設定成功*/
    } catch (err) {
      type = 'error'
      message = i18n.t('ai_setting_fail') /*設定失敗*/
    }

    Vue.prototype.$notify({
      title: i18n.t('ai_recognition_setting') /*辨識設定*/,
      message: message || '',
      type: type || 'error',
      // position: 'bottom-left'
    })
  },
  async getUserSubscribers({ commit, getters }) {
    try {
      const res = await apiGetUserSubscribers(getters.userIndex, 1) // 第2個參數 model => LPR: 1, OR: 2, FR: 3
      const data = res.status === 200 ? res.data : []
      commit('updateOrigSubscribers', data)
      commit('updateSubscribers', data)
    } catch (err) {
      console.log('發生錯誤：', err)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async handleUserSubscribers({ state, getters, dispatch, rootState }) {
    const origSubscribers = state.origSubscribers.map(subscriber => subscriber.subscriberId)
    const currSubscribers = state.subscribers.map(subscriber => subscriber.subscriberId)
    const addIds = currSubscribers.filter(subscriber => !origSubscribers.includes(subscriber))
    // 要 delete 接收人前, 需先檢查該接收人是否有在自己的可視 list 中, 若無, 則不能刪除該接收人
    const minusIds = origSubscribers.filter(subscriber => !currSubscribers.includes(subscriber) && 
      rootState.account.userList.find(user => user.index === Number(subscriber)))
    
    if (addIds.length !== 0 || minusIds.length !== 0) {
      let model = 1
      if (rootState.aibox.aiMode === 'fr') {
        model = 3
      } else if (rootState.aibox.aiMode === 'lpr') {
        model = 1
      }
      const promisesAdd = addIds.map(async(id) => {
        const data = {
          userId: String(getters.userIndex),
          subscriberId: id,
          model: model, // model: 1:LPR, 2:OR, 3:FR
        }
        await apiPostUserSubscriber(data)
      })

      const promisesDel = minusIds.map(async(id) => {
        const data = {
          userId: String(getters.userIndex),
          subscriberId: id,
          model: model, // model: 1:LPR, 2:OR, 3:FR
        }
        await apiDeleteUserSubscriber(data)
      })

      const promises = promisesAdd.concat(promisesDel)

      Promise.all(promises)
      .then(() => {
        dispatch('getUserSubscribers')
        Vue.prototype.$notify({
          title: i18n.t('ai_subscriber') /*接收人*/,
          message: i18n.t('ai_setting_success') /*設定成功*/,
          type: 'success',
          // position: 'bottom-left'
        })
      })
      .catch(() => {
        dispatch('getUserSubscribers')
        Vue.prototype.$notify({
          title: i18n.t('ai_subscriber') /*接收人*/,
          message: i18n.t('ai_setting_fail') /*設定失敗*/,
          type: 'error',
          // position: 'bottom-left'
        })
      })
    }
  },
  restoreData({ state, commit }) {
    commit('updateCurrDefaultSetting', state.origDefaultSetting)
    
    if (state.origDefaultSetting === 1) {
      commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
    } else {
      if (Object.keys(state.userSetting).length === 0 || Object.keys(state.userSetting.setting).length === 0) {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
      } else {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.userSetting)))        
      }
    }
    commit('updateNote', state.origNote)
    commit('updateNotifyFilterMode', state.origNotifyFilterMode)
    commit('updateSubscribers', state.origSubscribers)
  },
  async sendLineNotify({ state, rootGetters }) {
    // 取得 LINE notify webhooks --> 針對有啟用的 webhook 發送通知 (呼叫 edit webhook api)
    try {
      // const modelMap = { lpr: 1, or: 2, fr: 3 }
      const params = { 
        userIds: String(rootGetters.rightClickUser.index),
        models: 1
      }
      const res = await apiGetUserWebhooks(params)
      const webhooks = res.data
      webhooks.forEach(webhook => {
        if (webhook.enabled === 1) {
          apiEditUserWebhook({ 
            id: webhook.id,
            userId: webhook.userId,
          })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}



export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}