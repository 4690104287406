// ISO 639-1代碼表
// https://zh.wikipedia.org/zh-hant/ISO_639-1%E4%BB%A3%E7%A0%81%E8%A1%A8

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './lang/en.js'
import zh from './lang/zh.js'
import ja from './lang/ja.js'
import localeEN from 'element-ui/lib/locale/lang/en'
import localeTW from 'element-ui/lib/locale/lang/zh-TW'
import localeJA from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'

Vue.use(VueI18n)

// element UI 所使用的多語系切換
locale.i18n((key, value) => i18n.t(key, value))

const messages = {
  en: {
    ...en,
    ...localeEN,
  },
  zh: {
    ...zh,
    ...localeTW,
  },
  ja: {
    ...ja,
    ...localeJA,
  }
}

export const getLang = (navLang) => {
  let lang = 'en'
  if (navLang === 'zh-TW' || navLang === 'zh') lang = 'zh'
  if (navLang === 'en-US' || navLang === 'en') lang = 'en'
  if (navLang === 'ja' || navLang === 'jpn') lang = 'ja'

  return lang
}

const lang = getLang(navigator.language)
// let lang = 'en'
// if (navLang === 'zh-TW') lang = 'zh'
// if (navLang === 'en-US' || navLang === 'en') lang = 'en'
// if (navLang === 'ja' || navLang === 'jpn') lang = 'ja'

const i18n = new VueI18n({
  locale: lang,
  messages
})

export default i18n

export const supportLangs = Object.keys(messages).map((key) => {
  return { key, str: i18n.t(`lang_${key}`) }
})
