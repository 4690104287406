import {
  apiCheckStatus,
  apiGetDeviceModels,
  apiGetGroupList,
  apiGetGroupTree,
  apiGetUserList, // 顯示用的 帳號列表
} from '@/api/index.js'
import { getDeviceModelIcon } from '@/config/account.js'
import { getGroupObj } from './func.js'

// 目標：
// dashboard, aibox, history 可以共用的 帳號/使用者/設備 樹狀資料

const initialState = () => ({
  deviceModels: [], // 設備類型

  groupList: [], // 群組列表
  accList: [], // 完整帳號列表
  userList: [], // 使用者列表
  deviceList: [], // 設備列表

  groupTreeList: [], // 群組樹
  accTreeList: [], // 群組 + 帳號 樹
  // userTreeList: [], // 群組 + 使用者 樹 // TODO：公開/私有
  // deviceTreeList: [], // 群組 + 設備 樹 // TODO：公開/私有
})

const state = initialState()

const mutations = {
  updateDeviceModels(state, payload) {
    state.deviceModels = payload
  },
  // Group ---
  updateGroupList(state, payload) {
    state.groupList = payload
  },
  updateGroupTreeList(state, payload) {
    state.groupTreeList = payload
  },
  // Account ---
  updateAccountList(state, payload) {
    state.accList = payload
  },
  updateAccountTreeList(state, payload) {
    state.accTreeList = payload
  },
  // User ---
  updateUserList(state, payload) {
    state.userList = payload
  },
  updateUserTreeList(state, payload) {
    state.userTreeList = payload
  },
  // Device ---
  updateDeviceList(state, payload) {
    state.deviceList = payload
  },
  updateDeviceTreeList(state, payload) {
    state.deviceTreeList = payload
  },
}

const actions = {
  async getDeviceModels({ commit }) {
    let list = []

    try {
      const res = await apiGetDeviceModels()

      if (!apiCheckStatus(res)) throw res
      if (!Array.isArray(res.data)) throw new Error('Not Array')

      list = res.data.map((model) => {
        const { id, name } = model
        const icon = getDeviceModelIcon(id)
  
        // Note: API /device/models 的 id 是 String, userInfo.deviceModelId 是 Number
        // 前端統一成 Number, 會容易比對
        return { id: Number(id), name, icon }
      })
    } catch (err) {
      console.error(`Store.global.getDeviceModels fail`, err)
    } finally {
      commit('updateDeviceModels', list)
    }
  },
  async getGroupList({ commit }) {
    let list = []
    try {
      const res = await apiGetGroupList()

      if (!apiCheckStatus(res)) throw res
      if (!Array.isArray(res.data)) throw new Error('Not Array')

      list = JSON.parse(JSON.stringify(res.data))
    } catch (err) {
      console.error(`Store.global.getGroupList fail`, err)
    } finally {
      commit('updateGroupList', list)
    }
  },
  async getGroupTreeList({ commit }) {
    let list = []
    try {
      const res = await apiGetGroupTree()
      if (!apiCheckStatus(res)) throw res

      const tree = JSON.parse(JSON.stringify(res.data))
      list = getGroupObj(tree, true)
    } catch (err) {
      console.error(`Store.global.getGroupTreeList fail`, err)
    } finally {
      commit('updateGroupTreeList', list)
    }
  },
  async getAccountList({ commit }) {
    let list = []
    try {
      const res = await apiGetUserList(null) // 缺少登入者資訊

      if (!apiCheckStatus(res)) throw res
      if (!Array.isArray(res.data)) throw new Error('Not Array')

      list = JSON.parse(JSON.stringify(res.data))
      // console.log(`[getAccountList] list:`, list)
    } catch (err) {
      console.error(`Store.global.getAccountList fail`, err)
    } finally {
      commit('updateAccountList', list)
      // TODO：群組樹 + 帳號
      // commit('updateAccountTreeList', [])
    }
  },
  async getUserList({ state, commit }) {
    let list = state.accList || []

    // TODO
    commit('updateUserList', list.filter((_acc) => _acc.kind !== 2))
    // TODO：群組樹 + 使用者
    // commit('updateUserTreeList', [])
  },
  async getDeviceList({ state, commit }) {
    let list = state.accList || []

    // TODO
    commit('updateDeviceList', list.filter((_acc) => _acc.kind === 0 || _acc.kind === 2))
    // TODO：群組樹 + 設備
    // commit('updateDeviceTreeList', [])
  },
  // 統一窗口
  async fetchDataAfterLogin({dispatch /*,commit*/, state, rootState}) {
    if (!rootState.isLogin /* || !rootState.accessToken*/) return

    if (state.deviceModels.length <= 0) { // 避免重複呼叫
      await dispatch('getDeviceModels')
    }

    if (state.groupList.length <= 0) { // 避免重複呼叫
      await dispatch('getGroupList')
    }

    // TODO: 測試中
    // dispatch('getGroupTreeList')
    dispatch('getAccountList')
    // dispatch('getUserList')
    // dispatch('getDeviceList')
  }
}

const getters = { }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
