import Vue from 'vue'
import i18n from "../../i18n/i18n"
import { 
  apiGetBoviaFrEvents,
  apiGetBoviaFrEventsCount
} from '@/api/index.js'
import { handleError } from '@/utils/handleError.js'
  
const initialState = () => ({
  searchLoading: false,
  frPageSize: 100, // 事件列表每個分頁事件數量
  frEventsTotal: 0, // 搜尋的總事件數
  frCurrentPage: 1, // 事件列表目前分頁所在頁數，從1開始
  frCurrentIndex: 0, // 事件在事件列表目前分頁的index, 從0開始
  frFocusIndex: -1, // 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  frEventList: [], // 僅存放該次搜尋的事件ex:100筆
  searchParams: {}, // 取得歷史人物事件參數
  beforeEventId: null, // 記錄目前分頁before event id
  afterEventId: null, // 記錄目前分頁after event id
  rank: 0, // 顯示人物資訊跳窗的rank值
})
  
const state = initialState()

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updateFrPageSize(state, value) {
    state.frPageSize = value
  },
  updateFrEventsTotal(state, value) {
    state.frEventsTotal = value
  },
  updateFrCurrentPage(state, value) {
    state.frCurrentPage = value
  },
  updateFrCurrentIndex(state, value) {
    state.frCurrentIndex = value
  },
  updateFrFocusIndex(state, value) {
    state.frFocusIndex = value
  },
  updateFrEventList(state, list) {
    state.frEventList = list
    // add uid for each event
    state.frEventList.forEach(event => {
      event.uid = `fr-${event.id}`
    })
  },
  updateSearchParams(state, payload) {
    state.searchParams = payload
  },
  updateBeforeEventId(state, value) {
    state.beforeEventId = value
  },
  updateAfterEventId(state, value) {
    state.afterEventId = value
  },
  updateRank(state, payload) {
    state.rank = payload
  },
}

const actions = {
  async getFrEventsTotal({ commit, state }) {
    commit('updateFrEventTotal', 0)
    const res = await apiGetBoviaFrEventsCount(state.searchParams)
    commit('updateFrEventTotal', res.data.total)
  },
  async searchFrEvents({ commit, state, dispatch }, payload) {
    commit('updateSearchLoading', true)
    const params = { ...state.searchParams, limit: state.frPageSize }
    let bGoPrev = false // 是否回到前一分頁
    if (payload.page !== 1) {
      if (payload.page > state.frCurrentPage) {
        delete params.after
        params.before = state.beforeEventId
      } else {
        delete params.before
        params.after = state.afterEventId
        bGoPrev = true
      }  
    }

    // 因為搜尋參數中, missioned, missionCodes, executed為陣列，所以需要將搜尋的參數再處理
    let urlSearchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          urlSearchParams.append(key, item)
        })
      } else
        urlSearchParams.append(key, value)
    })

    // 取得事件
    try {
      const res = await apiGetBoviaFrEvents(urlSearchParams)
      const len = res.data?.length
      if (len > 0) {
        // 若用after取資料id會從小到大，須先排序
        res.data.sort((x, y) => y.id - x.id)
        // 資料id由大到小
        commit('updateFrEventList', res.data)
        commit('updateBeforeEventId', res.data[len - 1].id)
        commit('updateAfterEventId', res.data[0].id)
        commit('updateFrCurrentPage', payload.page)

        // 若是回到前一分頁，eventIndex更新為前一分頁最後一筆，下一頁則是第一筆
        let eventIndex = bGoPrev ? len - 1 : 0 
        commit('updateFrCurrentIndex', eventIndex)
        commit('updateFrFocusIndex', eventIndex)
      } else {
        Vue.prototype.$notify.warning({
          title: i18n.t('search_hint')/*提示*/,
          message: i18n.t('search_hint_nodata')/* 查無資料 */
        })
      }
    } catch (error) {
      handleError(error)
      dispatch('resetHistoryFrData')
    } finally {
      commit('updateSearchLoading', false)
    }

    // 取得事件總數(當取第一頁並且不是回上頁)
    try {
      if (payload.page === 1 && !bGoPrev) {
        commit('updateFrEventsTotal', 0)
        const resTotal = await apiGetBoviaFrEventsCount(urlSearchParams)
        commit('updateFrEventsTotal', resTotal.data.total)
      } 
    } catch (err) {
      console.log('apiGetBoviaFrEventsCount error: ', err)
      commit('updateFrEventsTotal', 0)
    }
  },
  resetHistoryFrData({ commit }) {
    commit('updateFrEventList', [])
    commit('updateFrEventsTotal', 0) // 搜尋的總事件數
    commit('updateFrCurrentPage', 1) // 事件列表目前分頁所在頁數
    commit('updateFrCurrentIndex', 0) // 事件在事件列表目前分頁的index
    commit('updateFrFocusIndex', -1) // focus事件在事件列表目前分頁的index
  },
}
const getters = {
  frModalEvent(state) {
    return state.frEventList[state.frCurrentIndex]
  },
  totalFrEventNo(state) {
    // 目前事件在所有事件的編號，從１開始
    return (state.frCurrentPage - 1) * state.frPageSize + state.frCurrentIndex + 1
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}