import CryptoJS from "crypto-js"

const key = '2TPJ2JsmHlCeUKvrz9gH3I9e7utAz4paHJdcCFvVNRQ='

export default {
  encrypt(txt) {
    const encryptd = CryptoJS.AES.encrypt(txt, key)
    return encryptd.toString()
  },
  decrypt(txt) {
    const decryptd = CryptoJS.AES.decrypt(txt, key) 
    return decryptd.toString(CryptoJS.enc.Utf8)
  }
}