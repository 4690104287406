const vueClearIndex = {}

/**
 * 解決 tooltips, 在彈窗之後再次出現的問題，原因是由於 tabindex 導致
 */
vueClearIndex.install = Vue => {
  Vue.directive('delTabIndex', {
    bind(el) {
      el.__vueSetTimeoutIndex__ = setTimeout(() => {
        el.removeAttribute('tabindex')
        clearTimeout(el.__vueSetTimeoutIndex__)
      }, 0)
    },
    unbind(el) {
      clearTimeout(el.__vueSetTimeoutIndex__)
    }
  })  
}

export default vueClearIndex