import { apiGetUserList } from '@/api/index.js'
import { apiGetAiBoxes } from '@/api/v4'

const state = {}
const mutations = {}
const actions = {}

// === modules ===
const aiboxMgr = {
  namespaced: true,
  state: () => ({
    perPageRecords: 30, // 1000,
    aiBoxes: [],
    aiBoxFilterText: '',
    ipFilterText: '',
    currPage: '1',
    userList: []
  }),
  getters: {
    filteredAiBoxes(state /*, getters, rootState*/) {
      const { aiBoxFilterText, ipFilterText, aiBoxes } = state
      const nameKey = aiBoxFilterText.toLowerCase()
      const ipKey = ipFilterText.toLowerCase()
      const filtered = aiBoxes.filter((aiBox) => {
        // 過濾 AiBox.name
        const name = aiBox.name.toLowerCase()
        const nameRet = name.indexOf(nameKey) >= 0 ? true : false
        // 過濾 AiBox.ip
        const ipRet = aiBox.ip.indexOf(ipKey) >= 0 ? true : false

        return nameRet && ipRet
      })

      const { currPage, perPageRecords } = state
      const pageIndex = parseInt(currPage, 10) - 1

      return filtered.splice(pageIndex * perPageRecords, perPageRecords)
    },
    pages(state, getters /*, rootState*/) {
      const len = getters.filteredAiBoxes.length
      const leave = len % state.perPageRecords > 0 ? 1 : 0

      return Math.floor(len / state.perPageRecords) + leave
    },
    fullPages(state /*, getters, rootState*/) {
      const { aiBoxes, perPageRecords } = state
      const len = aiBoxes.length
      const leave = len % perPageRecords > 0 ? 1 : 0

      return Math.floor(len / state.perPageRecords) + leave
    }
  },
  mutations: {
    updateAiBoxes(state, payload /*list*/) {
      state.aiBoxes = payload
    },
    updateAiBoxFilterText(state, payload) {
      state.aiBoxFilterText = payload
    },
    updateIpFilterText(state, payload) {
      state.ipFilterText = payload
    },
    updateCurrPage(state, payload) {
      state.currPage = payload
    },
    updateUserList(state, payload) {
      state.userList = payload
    }
  },
  actions: {
    async getAiBoxes({ commit }) {
      try {
        const res = await apiGetAiBoxes()
        commit('updateAiBoxes', res.data)
        // commit('updateAiBoxes', [])
      } catch (err) {
        console.log(err)
      }
    },
    async getUserList({ commit }) {
      try {
        const res = await apiGetUserList(null)
        commit('updateUserList', res.data)
      } catch (err) {
        console.log(err)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters: {},
  modules: {
    aiboxMgr
  }
}